import React, { useState } from 'react';
import AppContainer from "../components/shared/AppContainer";
import AppHeader from "../components/shared/AppHeader";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import { Box, Typography, IconButton, InputAdornment, CircularProgress, useMediaQuery, Stack, Card } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AppTextInput from "../components/shared/AppTextInput";
import AppButton from "../components/shared/AppButton";
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import Logo from '../assets/Images/anesi-Logo.png'
import { useUser } from "../contexts/UserProvider";
import { useAlert } from "../contexts/AlertsProvider";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const TextContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '1em',
    textAlign: 'center',
    gap: '1em',
});

const InputContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '1em',
    marginBottom: '1.25em',
    width: '100%',
    gap: '.75em',
});

const ButtonContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
});

const OrContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '.25em',
});

const ResetContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1em',
    '@media (min-width:1024px)': {
        marginTop: '2em',
        marginBottom: '0em'
    }
});

const Spacer = styled(Box)({
    flex: '1 1 auto',
});

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is Required'),
    password: Yup.string().required('Password is Required'),
});

const LogoContainer = styled(Box)(({ theme, isdesktop }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1em',
    marginBottom: '1.25em',
    '& img': {
        width: isdesktop ? '5em' : '25%'
    }
}));

const ResponsiveContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
});

const DesktopContainer = styled(Card)(({ theme }) => ({
    width: '40%',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: '16px',
    padding: '30px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    border: '1px solid rgba(145, 158, 171, 0.1)',
}));

const LoginForm = ({ loading, handleSubmit, showPassword, handleShowPassword, rememberMe, handleRememberMeChange, savedEmail, navigate }) => (
    <Formik initialValues={{ email: savedEmail, password: '' }} onSubmit={handleSubmit} validationSchema={validationSchema}>
        <Form>
            <InputContainer>
                <AppTextInput label="Email" name="email" />
                <AppTextInput
                    type={showPassword ? "text" : "password"}
                    label="Password"
                    name="password"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleShowPassword}>
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <FormControlLabel
                    control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: '1.20em' }, color: '#37589D', '&.Mui-checked': { color: '#4EB6B5' } }} checked={rememberMe}
                                       onChange={handleRememberMeChange} name="rememberMe" />}
                    label={
                        <Typography fontWeight="700" color="#959292">
                            Remember Me
                        </Typography>
                    }
                    style={{ marginLeft: '0.1em', marginTop: '-0.5em' }}
                />
            </InputContainer>
            <ButtonContainer>
                <div style={{ position: 'relative' }}>
                    <AppButton variant="primary" type="submit" disabled={loading}>
                        Sign-In
                    </AppButton>
                    {loading && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12 }} />}
                </div>
                <OrContainer>
                    <Typography fontWeight="700">or</Typography>
                </OrContainer>
                <AppButton variant="secondary" onClick={() => navigate('/register')}>Register</AppButton>
            </ButtonContainer>
        </Form>
    </Formik>
);

function LogInScreen(props) {
    const isDesktop = useMediaQuery('(min-width:1024px)');
    const isTablet = useMediaQuery('(min-width:768px)');
    const navigate = useNavigate();
    const location = useLocation();
    const { login } = useUser();
    const alert = useAlert();
    const [loading, setLoading] = useState(false);
    const savedEmail = localStorage.getItem("rememberMeEmail") || '';
    const [rememberMe, setRememberMe] = useState(Boolean(savedEmail));
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState(savedEmail);
    const isSmallScreen = useMediaQuery('(max-width:359px)');

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleRememberMeChange = (event) => {
        setRememberMe(event.target.checked);
        if (event.target.checked) {
            localStorage.setItem("rememberMeEmail", email);
        } else {
            localStorage.removeItem("rememberMeEmail");
        }
    };

    const handleSubmit = async (values) => {
        setLoading(true);
        const { email, password } = values;
        if (rememberMe) {
            localStorage.setItem("rememberMeEmail", email);
        } else {
            localStorage.removeItem("rememberMeEmail");
        }
        const result = await login(email, password);
        if (result === 'fail') {
            alert('Incorrect email or password', 'error');
        } else if (result === 'unapproved') {
            alert('Your account has not been approved', 'error');
        } else if (result === 'ok') {
            let next = '/';
            if (location.state && location.state.next) {
                next = location.state.next;
            }
            navigate(next);
        }
        setLoading(false);
    };

    return (
        <AppContainer>
            {!isDesktop && (<AppHeader backButtonRoute="/" />)}
            <LogoContainer isdesktop={isDesktop ? 1 : 0}>
                <img src={Logo} alt="Anesi Logo" />
            </LogoContainer>
            <ResponsiveContainer>
                {isDesktop ? (
                    <DesktopContainer>
                        <Box width="85%">
                            <TextContainer>
                                <Typography fontWeight="700" style={{ fontSize: isSmallScreen ? '1.125em' : '1.25em' }}>Welcome Back</Typography>
                                <Typography fontWeight="700" color="#959292">Please enter in your credentials.</Typography>
                            </TextContainer>
                            <LoginForm
                                loading={loading}
                                handleSubmit={handleSubmit}
                                showPassword={showPassword}
                                handleShowPassword={handleShowPassword}
                                rememberMe={rememberMe}
                                handleRememberMeChange={handleRememberMeChange}
                                savedEmail={savedEmail}
                                navigate={navigate}
                            />
                        </Box>
                    </DesktopContainer>
                ) : (
                    <Stack width="100%">
                        <TextContainer>
                            <Typography fontWeight="700" style={{ fontSize: isSmallScreen ? '1.125em' : '1.25em' }}>Welcome Back</Typography>
                            <Typography fontWeight="700" color="#959292">Please enter in your credentials.</Typography>
                        </TextContainer>
                        <LoginForm
                            loading={loading}
                            handleSubmit={handleSubmit}
                            showPassword={showPassword}
                            handleShowPassword={handleShowPassword}
                            rememberMe={rememberMe}
                            handleRememberMeChange={handleRememberMeChange}
                            savedEmail={savedEmail}
                            navigate={navigate}
                        />
                    </Stack>
                )}
            </ResponsiveContainer>
            <Spacer />
            <ResetContainer>
                <Typography
                    onClick={() => navigate('/password_reset')}
                    fontWeight="bold"
                    style={{ cursor: 'pointer' }}
                >
                    Forgot Password?
                </Typography>
            </ResetContainer>
        </AppContainer>
    );
}

export default LogInScreen;
