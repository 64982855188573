import React, { useState } from 'react';
import { Box, CardActionArea, IconButton, Typography, useMediaQuery } from "@mui/material";
import { useGesture } from '@use-gesture/react';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import {CustomNameUtils} from "../../utils/CustomNameUtils";

function UnitCard({ unit, contractor, navigate, source, swipeIcon, onSwipe, showInfo, onInfoClick }) {
    const isSmallScreen = useMediaQuery('(max-width:359px)');
    const [swipeX, setSwipeX] = useState(0); // Track horizontal drag
    const [showActionIcons, setShowActionIcons] = useState(false);

    // Gesture binding using @use-gesture
    const bind = useGesture({
        onDrag: ({ movement: [x], down, velocity }) => {
            const swipeLimit = -100; // Maximum swipe to reveal action buttons

            // Smooth drag movement to the left
            if (x < 0 && x >= swipeLimit) {
                setSwipeX(x); // Update swipeX as user drags
            }

            // If user swipes far enough, reveal the action buttons
            if (x <= swipeLimit) {
                setShowActionIcons(true); // Show action buttons
            }

            // Smooth drag movement back to the right (reset position)
            if (x > 0) {
                setSwipeX(0); // Reset swipeX if swiping right
                setShowActionIcons(false); // Hide action buttons if swiped back
            }
        },
        onDragEnd: ({ movement: [x] }) => {
            const swipeThreshold = -90;

            if (x <= swipeThreshold) {
                setSwipeX(-100); // Snap to -100px when swiped far enough
                setShowActionIcons(true); // Keep the action buttons visible
            } else {
                setSwipeX(0); // Reset to original position
                setShowActionIcons(false); // Hide action buttons
            }
        }
    });

    const handleActionClick = () => {
        if (onSwipe) {
            onSwipe(unit.id);
        }
    };

    return (
        <Box style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
            <Box
                {...bind()}
                style={{
                    position: 'relative',
                    backgroundColor: '#ffffff',
                    border: '1px solid #ccc',
                    borderRadius: '10px',
                    width: '100%',
                    overflow: 'hidden',
                    transition: 'transform 0.3s ease',
                    transform: `translateX(${swipeX}px)`,
                    touchAction: 'none'
                }}
            >
                <CardActionArea onClick={() => navigate(`/dashboard/my_units/${unit.id}?source=${source}`)}>
                    <Box display="flex" flexDirection="column" justifyContent="center" p={2}>
                        <Typography fontWeight="bold" fontSize={isSmallScreen ? "0.9em" : "1em"}>
                            {CustomNameUtils(unit, contractor)}
                        </Typography>
                        <Typography fontSize={isSmallScreen ? "0.9em" : "1em"} fontWeight="700" color="#989898">
                            SN: {unit.serial_number}
                        </Typography>
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: -1,
                                backgroundColor: unit.faults?.length > 0 ? 'error.main' : 'success.main',
                                color: 'white',
                                px: 1.5,
                                py: 0.5,
                                borderTopRightRadius: '10px',
                                borderBottomLeftRadius: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {unit.faults?.length > 0 ? 'Error' : 'Operational'}
                        </Box>

                    </Box>
                </CardActionArea>
            </Box>


            {showActionIcons && (
                <Box
                    sx={{
                        position: 'absolute',
                        right: 0,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.5,
                    }}
                >
                    <IconButton
                        color="error"
                        onClick={handleActionClick}
                    >
                        {swipeIcon || <DeleteIcon />}
                    </IconButton>

                    {contractor && showInfo && (
                        <IconButton
                            color="secondary"
                            onClick={onInfoClick}
                        >
                            <InfoIcon />
                        </IconButton>
                    )}
                </Box>
            )}
        </Box>
    );
}

export default UnitCard;
