import React from 'react';
import AppContainer from "../components/shared/AppContainer";
import styled from "@emotion/styled";
import AppHeader from "../components/shared/AppHeader";
import {
    Box,
    Card,
    Stack,
    useMediaQuery
} from "@mui/material";

import RegisterOne from "../components/registration/RegisterOne";
import RegisterTwo from "../components/registration/RegisterTwo";
import RegisterThree from "../components/registration/RegisterThree";
import MultiStepProvider from "../contexts/MultiStepProvider";
import { StepProvider, StepContext } from "../contexts/StepContext";
import RegisterConPassword from "../components/registration/RegisterConPassword";
import Register2FA from "../components/registration/Register2FA";

import Logo from "../assets/Images/anesi-Logo.png";


const LogoContainer = styled(Box)(({ theme, isdesktop }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1em',
    marginBottom: !isdesktop ? '2em' : '1.25em',
    '& img': {
        width: isdesktop ? '5em' : '20%'
    }
}));

const ResponsiveContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
});

const DesktopContainer = styled(Card)(({ theme }) => ({
    width: '40%',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: '16px',
    padding: '30px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    border: '1px solid rgba(145, 158, 171, 0.1)',
}));

const MainContentWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
});

function RegisterScreen(props) {
    const isDesktop = useMediaQuery('(min-width:1024px)');
    const steps = [<RegisterOne />, <RegisterTwo />, <Register2FA />, <RegisterConPassword />, <RegisterThree />];

    return (
        <MultiStepProvider>
            <StepProvider>
                <AppContainer>
                    {!isDesktop && <AppHeader title="Registration" backButtonRoute="/login" />}
                    <MainContentWrapper>
                        <LogoContainer isdesktop={isDesktop ? 1 : 0}>
                            <img src={Logo} alt="Anesi Logo"/>
                        </LogoContainer>
                        <ResponsiveContainer>
                            {isDesktop ? (
                                <DesktopContainer>
                                    <Box width="100%" >
                                        <StepContext.Consumer>
                                            {({step}) => (
                                                <>
                                                    {steps[step]}
                                                </>
                                            )}
                                        </StepContext.Consumer>
                                    </Box>
                                </DesktopContainer>
                            ) : (
                                <Stack width="100%">
                                    <StepContext.Consumer>
                                        {({step}) => <>{steps[step]}</>}
                                    </StepContext.Consumer>
                                </Stack>
                            )}
                        </ResponsiveContainer>
                    </MainContentWrapper>
                </AppContainer>
            </StepProvider>
        </MultiStepProvider>
    )
}

export default RegisterScreen;
