import React from 'react';
import Lottie from 'react-lottie';
import lottieJson from '../Lotties/Success_SMTI.json';

const SuccessAnimation = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: lottieJson,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return <Lottie options={defaultOptions} height={75} width={75} />;
};

export default SuccessAnimation;
