import { createContext, useState } from 'react';

export const StepContext = createContext();

export const StepProvider = ({ children }) => {
    const [step, setStep] = useState(0);

    const nextStep = () => setStep(prevStep => prevStep + 1);
    const prevStep = () => setStep(prevStep => prevStep - 1);
    const goToStep = (stepNumber) => setStep(stepNumber);


    return (
        <StepContext.Provider value={{ step, setStep, nextStep, prevStep, goToStep }}>
            {children}
        </StepContext.Provider>
    );
};
