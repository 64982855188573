import React from 'react';
import { TextField, InputAdornment } from '@mui/material';
import styled from '@emotion/styled';

const StyledSearchInputField = styled(TextField)({
    marginTop: '2.5em',
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#37589D',
            borderWidth: 1,
            borderRadius: 7,
        },
        '&.Mui-focused fieldset': {
            borderColor: '#37589D',
        },
        '& .MuiInputBase-input': {
            height: '2.5em',
            padding: '0 1em',
        },
    },
});

const AppSearchBar = ({ value, onChange, placeholder, endAdornment }) => {
    return (
        <StyledSearchInputField
            variant="outlined"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            InputProps={{
                endAdornment: endAdornment ? (
                    <InputAdornment position="end">
                        {endAdornment}
                    </InputAdornment>
                ) : null,
            }}
        />
    );
};

export default AppSearchBar;
