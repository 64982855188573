import React, {useState} from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import AppContainer from "../components/shared/AppContainer";
import AppHeader from "../components/shared/AppHeader";
import styled from "@emotion/styled";
import {Box, CircularProgress, Typography} from "@mui/material";
import AppTextInput from "../components/shared/AppTextInput";
import AppButton from "../components/shared/AppButton";
import {useApi} from "../contexts/ApiProvider";
import {useAlert} from "../contexts/AlertsProvider";

const MainContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '2em',
    gap: '0.25em',
});

const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Email is Required'),
});

function PasswordResetScreen(props) {
    const api = useApi();
    const alert = useAlert();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (values) => {
        setLoading(true);
        const response = await api.post('/tokens/reset', values);
        console.log(response);
        if (response.ok) {
            alert('You will receive an email with instructions to reset your password', 'success');
        } else if (response.status === 404) {
            alert('Email address could not be found', 'error')
        } else {
            alert('There was an error with your request', 'error')
        }
        setLoading(false);
    }

    return (
        <AppContainer>
            <AppHeader title="Password Reset" backButtonRoute="/login"/>
            <MainContainer>
                <Typography variant="h6">Need Password Help?</Typography>
                <Typography style={{ textAlign: 'center', color: '#959292' }}>Enter the email associated with your account and we’ll send you instructions to reset your password.
                </Typography>
            </MainContainer>
            <Formik initialValues={{ email: ''}} onSubmit={handleSubmit} validationSchema={validationSchema}>
                <Form>
                    <AppTextInput style={{ width: '100%'}} name="email" label="Email"  type="email" placeholder="me@email.com"/>
                    <div style={{ position: 'relative' }}>
                        <AppButton disabled={loading} variant="primary" type="submit" style={{ marginTop: '1em' }}>Request</AppButton>
                        {loading && <CircularProgress size={24} style={{ position: 'absolute', top: '60%', left: '50%', marginTop: -12, marginLeft: -12 }} />}
                    </div>
                </Form>
            </Formik>
        </AppContainer>
    );
}

export default PasswordResetScreen;

