import React, {useState} from 'react';
import AppContainer from "../components/shared/AppContainer";
import styled from "@emotion/styled";
import {
    Box, Card,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText, Stack,
    Typography, useMediaQuery
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AppButton from "../components/shared/AppButton";
import {useNavigate} from "react-router-dom";
import Logo from '../assets/Images/anesi-Logo.png';
import SMTI_logo from '../assets/Images/smti_logo.png'
import Products_photo from '../assets/Images/anesi_products.png';
import AppDivider from "../components/shared/AppDivider";

const MainContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1em',
});

const ButtonsContainer = styled(Box)({
    marginBottom: '1em',
    display: 'flex',
    flexDirection: 'column',
    gap: '.25em',
    width: '100%',
});

const LogoContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1em',
    marginTop: '1em',
});

const BottomContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexDirection: 'column',
    '@media (min-width: 768px)': {
        width: '90%',
        alignSelf: 'center'
    }
});

const StyledDrawer = styled(Drawer)({
    '& .MuiDrawer-paper': {
        backgroundColor: '#F5F5F5',
        borderRight: '2.5px solid #4EB6B5',
        '@media (max-width: 389px)': {
            width: '75%',
        },
    },
});

const CenteredListItem = styled(ListItem)({
    justifyContent: 'center',
    marginTop: '1em',
    '& .MuiTypography-root': {
        fontSize: '1em',
        '@media (min-width: 768px)': {
            fontSize: '1.25em',
        },
    },
});

const ResponsiveContainer = styled(Box)({
    '@media (min-width:1024px)': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        overflow: 'hidden',
    }
})

const BannerContainer = styled(Box)({
    width: '100%',
    height: '3.5em',
    backgroundColor: '#4EB6B5',
    textAlign: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    padding: '0.25em'
})

const Banner = () => {
    const bannerText = 'You are on a dev. version of this app. Please use anesi.28gorilla.com';

    return (
        <BannerContainer>
            <Typography color="white">
                {bannerText}
            </Typography>
        </BannerContainer>
    );
};

function HomeScreen(props) {
    const isDesktop = useMediaQuery('(min-width:1024px)');
    const isTablet = useMediaQuery('(min-width:768px)');
    const navigate = useNavigate();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const environment = 'production';

    return (
        <ResponsiveContainer>
            {environment === 'development' && <Banner environment={environment} />}
            {!isDesktop && (
                <AppContainer justifyContent='space-between'>
                    <Box>
                        <IconButton
                            onClick={toggleDrawer}
                            style={{ alignSelf: 'flex-start' }}
                        >
                            <MenuIcon fontSize="small" />
                        </IconButton>
                        <LogoContainer>
                            <img style={{ width: isTablet ? '20%' : '30%' }} src={Logo} alt="Anesi Logo" />
                        </LogoContainer>
                    </Box>
                    <MainContainer>
                        <img src={Products_photo} alt="Anesi products" style={{ width: isTablet ? '50%' : '65%' }}/>
                    </MainContainer>
                    <BottomContainer>
                        <ButtonsContainer>
                            <AppButton variant="primary" onClick={() => navigate('/login')}>
                                Sign-in
                            </AppButton>
                            <AppButton variant="secondary" onClick={() => navigate('/register')}>
                                Register
                            </AppButton>
                        </ButtonsContainer>
                        <Box display="flex" flexDirection="row" gap="1em" alignItems="center" justifyContent="flex-end">
                            <Typography fontSize=".75em">By Stone Mountain Technologies, Inc.</Typography>
                            <img style={{ width: "7%"}} src={SMTI_logo} alt=" SMTI Logo" />
                        </Box>
                    </BottomContainer>
                    <StyledDrawer open={drawerOpen} onClose={toggleDrawer}>
                        <List sx={{ padding: '0'}}>
                            <CenteredListItem>
                                <Typography paddingX={5} fontWeight="700">Get in Touch</Typography>
                            </CenteredListItem>
                            <AppDivider />
                            <ListItem>
                                <ListItemButton href="tel:423-735-7400">
                                    <ListItemIcon>
                                        <LocalPhoneIcon style={{ color: '#37589D' }} />
                                    </ListItemIcon>
                                    <ListItemText>
                                        Call Us
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                            <ListItem>
                                <ListItemButton href="mailto:info@stonemtntechnologies.com">
                                    <ListItemIcon>
                                        <EmailIcon style={{ color: '#37589D' }} />
                                    </ListItemIcon>
                                    <ListItemText>
                                        Email Us
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                            <ListItem>
                                <ListItemButton onClick={() => navigate('/contractor_form')}>
                                    <ListItemIcon>
                                        <EngineeringIcon style={{ color: '#37589D' }} />
                                    </ListItemIcon>
                                    <ListItemText>
                                        Contractor Pre-Registration
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </StyledDrawer>
                </AppContainer>
            )}
            {isDesktop && (
                <Box position="absolute" top="0" left="0" p={2}>
                    <IconButton onClick={toggleDrawer}>
                        <MenuIcon fontSize="large" />
                    </IconButton>
                </Box>
            )}
            {isDesktop && (
                <Stack width="45%">
                    <img src={Logo} alt="Anesi Logo" style={{ width: '15%', marginBottom: '1em', alignSelf: 'center' }} />
                    <Card elevation={0} sx={{
                        padding: '30px',
                        borderRadius: '16px',
                        boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
                        border: '1px solid rgba(145, 158, 171, 0.1)'
                    }}>
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <img src={Products_photo} alt="Anesi products" style={{ width: '45%', marginBottom: '3em', marginTop: '2.5em'}} />
                            <Box width="85%">
                                <AppButton variant="primary" onClick={() => navigate('/login')}>
                                    Sign-in
                                </AppButton>
                                <AppButton variant="secondary" onClick={() => navigate('/register')}>
                                    Register
                                </AppButton>
                                <Box display="flex" flexDirection="row" gap="0.5em" alignItems="center" justifyContent="flex-end" mt="0.5em">
                                    <Typography fontWeight="600" fontSize=".75em">By Stone Mountain Technologies, Inc.</Typography>
                                    <img style={{ width: "5%"}} src={SMTI_logo} alt=" SMTI Logo" />
                                </Box>
                            </Box>
                        </Box>
                    </Card>
                </Stack>
            )}
            <StyledDrawer open={drawerOpen} onClose={toggleDrawer}>
                <List sx={{ padding: '0'}}>
                    <CenteredListItem>
                        <Typography paddingX={5} fontWeight="700">Get in Touch</Typography>
                    </CenteredListItem>
                    <AppDivider />
                    <ListItem>
                        <ListItemButton href="tel:423-735-7400">
                            <ListItemIcon>
                                <LocalPhoneIcon style={{ color: '#37589D' }} />
                            </ListItemIcon>
                            <ListItemText>
                                Call Us
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton href="mailto:info@stonemtntechnologies.com">
                            <ListItemIcon>
                                <EmailIcon style={{ color: '#37589D' }} />
                            </ListItemIcon>
                            <ListItemText>
                                Email Us
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton onClick={() => navigate('/contractor_form')}>
                            <ListItemIcon>
                                <EngineeringIcon style={{ color: '#37589D' }} />
                            </ListItemIcon>
                            <ListItemText>
                                Contractor Pre-Registration
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                </List>
            </StyledDrawer>
        </ResponsiveContainer>
    );
}

export default HomeScreen;
