import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {AnimatePresence} from "framer-motion";
import PublicRoute from "../components/navigation/PublicRoute";
import HomeScreen from "../views/HomeScreen";
import AppTransition from "../components/shared/AppTransition";
import LogInScreen from "../views/LogInScreen";
import RegisterScreen from "../views/RegisterScreen";
import PasswordResetScreen from "../views/PasswordResetScreen";
import PasswordResettingScreen from "../views/PasswordResettingScreen";
import PrivateRoute from "../components/navigation/PrivateRoute";
import DashboardScreen from "../views/DashboardScreen";
import SettingsScreen from "../views/SettingsScreen";
import MyAccountScreen from "../views/MyAccountScreen";
import MyUnitsScreen from "../views/MyUnitsScreen";
import AddUnitScreen from "../views/AddUnitScreen";
import UnitViewScreen from "../views/UnitViewScreen";
import ContractorDataScreen from "../views/ContractorDataScreen";
import DocumentsScreen from "../views/DocumentsScreen";
import FaultsScreen from "../views/FaultsScreen";
import ServicingAssistantScreen from "../views/ServicingAssistantScreen";
import PlotsScreen from "../views/PlotsScreen";
import AppContractorForm from "../components/registration/AppContractorForm";
import MyGroupsScreen from "../views/MyGroupsScreen";

const AppRoutes = () => {
    const location = useLocation();

    return (
        <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>
                <Route path="/" element={
                    <PublicRoute><HomeScreen /></PublicRoute>} />
                <Route path="/login" element={
                    <PublicRoute><AppTransition><LogInScreen /></AppTransition></PublicRoute>} />
                <Route path="/register" element={
                    <PublicRoute><AppTransition><RegisterScreen /></AppTransition></PublicRoute>} />
                <Route path="/contractor_form" element={
                    <PublicRoute><AppTransition><AppContractorForm /></AppTransition></PublicRoute>} />
                <Route path="/password_reset" element={
                    <PublicRoute><AppTransition><PasswordResetScreen /></AppTransition></PublicRoute>} />
                <Route path="/reset" element={
                    <PublicRoute><AppTransition><PasswordResettingScreen /></AppTransition></PublicRoute>} />
                <Route path="/dashboard/*" element={
                    <PrivateRoute>
                        <Routes>
                            <Route index element={<DashboardScreen />} />
                            <Route path="settings" element={<AppTransition><SettingsScreen /></AppTransition>} />
                            <Route path="my_account" element={<AppTransition><MyAccountScreen /></AppTransition>} />
                            <Route path="my_units/*" element={
                                <Routes>
                                    <Route index element={<AppTransition><MyUnitsScreen /></AppTransition>} />
                                    <Route path="groups" element={<AppTransition><MyGroupsScreen /></AppTransition>} />
                                    <Route path="add_unit" element={<AppTransition><AddUnitScreen /></AppTransition>} />
                                    <Route path=":unitId/*" element={
                                        <Routes>
                                            <Route index element={<AppTransition><UnitViewScreen /></AppTransition>} />
                                            <Route path="more-data" element={<AppTransition><ContractorDataScreen /></AppTransition>} />
                                            <Route path="documents" element={<AppTransition><DocumentsScreen /></AppTransition>} />
                                            <Route path="alerts" element={<AppTransition><FaultsScreen /></AppTransition>} />
                                            <Route path="assistant" element={<AppTransition><ServicingAssistantScreen /></AppTransition>} />
                                            <Route path="plot" element={<AppTransition><PlotsScreen /></AppTransition>} />
                                        </Routes>
                                    }/>
                                </Routes>} />
                        </Routes>
                    </PrivateRoute>
                } />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </AnimatePresence>
    );
}

export default AppRoutes;
