import React, {useState} from 'react';
import {MultiStepContext} from "./MultiStepContext";

function MultiStepProvider({children}) {
    const [formState, setFormState] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        termsAccepted: false,
        phoneNumber: '',
        company: '',
        userType: null,
        address: '',
        role: 0,
    });

    return (
        <MultiStepContext.Provider value={{ formState, setFormState}}>
            {children}
        </MultiStepContext.Provider>
    );
}

export default MultiStepProvider;
