import React, {useContext, useState, useRef, useEffect} from 'react';
import styled from "@emotion/styled";
import { Box, Typography, TextField, Grid } from "@mui/material";
import AppButton from "../shared/AppButton";
import { StepContext } from "../../contexts/StepContext";
import { MultiStepContext } from "../../contexts/MultiStepContext";
import {useApi} from "../../contexts/ApiProvider";
import {useAlert} from "../../contexts/AlertsProvider";

const TextContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '1em',
    textAlign: 'center',
});

const InputContainer = styled(Grid)({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1em',
    marginBottom: '1em',
});

const CodeInput = styled(TextField)({
    width: '3em',
    textAlign: 'center',
    '& input': {
        textAlign: 'center',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#ced4da',
        },
        '&:hover fieldset': {
            borderColor: '#ced4da',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#4EB6B5',
        },
    },
});

const ButtonContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '3em',
    marginTop: '1em',
});

function Register2FA(props) {
    const api = useApi();
    const alert = useAlert();
    const { nextStep } = useContext(StepContext);
    const { formState } = useContext(MultiStepContext);
    const [code, setCode] = useState(Array(6).fill(""));
    const inputs = useRef([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const send2FACode = async () => {
            try {
                const response = await api.post('/create_2fa', {
                    phone_number: formState.phoneNumber
                });
                if (response.ok) {
                    alert("2FA code sent to your phone!", "success");
                } else {
                    alert("Failed to send 2FA code", "error");
                }
            } catch (error) {
                console.error("Error sending 2FA code:", error);
                alert("Failed to send 2FA code", "error");
            }
        };
        send2FACode();
    }, [formState.phoneNumber, api, alert]);

    const handleChange = (value, index) => {
        if (isNaN(value)) return;
        const newCode = [...code];
        newCode[index] = value;
        setCode(newCode);

        if (value !== "" && index < 5) {
            inputs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && !code[index] && index > 0) {
            inputs.current[index - 1].focus();
        }
    };

    const handleContinue = async () => {
        const verificationCode = code.join("");
        setLoading(true);
        console.log(verificationCode);

        try {
            const response = await api.post('/verify_2fa', {
                phone_number: formState.phoneNumber,
                verification_code: verificationCode,
            });
            console.log(response);
            if (response.ok) {
                const contractorToken = response.body.contractor_token;
                localStorage.setItem("contractorToken", contractorToken);

                alert("2FA verified successfully!", "success");
                nextStep();
            } else {
                alert("Invalid 2FA code. Please try again.", "error");
            }
        } catch (error) {
            console.error("Error verifying 2FA code:", error);
            alert("Failed to verify 2FA code", "error");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <TextContainer>
                <Typography variant="h6">Two Step Verification</Typography>
                <Typography style={{ textAlign: 'center', color: '#959292', marginTop: '.25em', fontWeight: '700' }}>
                    Perfect! We've sent you a code to verify its really you. Please enter it below.
                </Typography>
            </TextContainer>
            <InputContainer container spacing={1}>
                {code.map((digit, index) => (
                    <Grid item key={index}>
                        <CodeInput
                            variant="outlined"
                            inputProps={{
                                maxLength: 1,
                                style: { textAlign: 'center' },
                            }}
                            value={digit}
                            onChange={(e) => handleChange(e.target.value, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            inputRef={(el) => (inputs.current[index] = el)}
                        />
                    </Grid>
                ))}
            </InputContainer>
            <ButtonContainer>
                <AppButton variant="primary" onClick={handleContinue}>
                    {loading ? "Verifying..." : "Verify"}
                </AppButton>
            </ButtonContainer>
        </>
    );
}

export default Register2FA;


