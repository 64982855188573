import React, {useContext, useEffect, useState} from 'react';
import styled from "@emotion/styled";
import { Box, Typography, InputAdornment, IconButton } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AppTextInput from "../shared/AppTextInput";
import AppButton from "../shared/AppButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { MultiStepContext } from "../../contexts/MultiStepContext";
import { StepContext } from "../../contexts/StepContext";
import { useApi } from "../../contexts/ApiProvider";
import AppDialog from "../shared/AppDialog";
import SuccessAnimation from "../../assets/Lotties/SuccessAnimation";
import {useNavigate} from "react-router-dom";

const TextContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '1em',
    textAlign: 'center',
});

const InputContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    gap: '.75em',
});

const ButtonContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '3em',
    marginTop: '2em',
});

const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
        .matches(
            /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            "Must contain 8 characters, one uppercase, one number and one special character"
        )
        .required('New password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required('Confirm password is required'),
});

function RegisterConPassword() {
    const api = useApi();
    const navigate = useNavigate();
    const { formState, setFormState } = useContext(MultiStepContext);
    const { nextStep, prevStep } = useContext(StepContext);
    const [showPassword, setShowPassword] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);

    const handleShowPassword = () => setShowPassword(!showPassword);
    useEffect(() => {
        return () => {
            localStorage.removeItem('contractorToken');
        };
    }, []);

    const handlePasswordSubmit = async (values) => {
        const contractorToken = localStorage.getItem('contractorToken');
        const passData = {
            email: formState.email,
            new_password: values.newPassword,
            contractor_token: contractorToken,
            phone_number: formState.phoneNumber,
        };

        try {
            const response = await api.put('/con_registration', passData);
            console.log(passData)
            console.log(response)
            if (response.status === 200) {
                setOpenSuccess(true)
                localStorage.removeItem('contractorToken');
            } else {
                console.error('Password creation failed');
            }
        } catch (error) {
            console.error('Password creation failed', error);
        }
    };

    const handleFinish = () => {
        setOpenSuccess(false);
        navigate('/')
    };

    return (
        <>
            <Formik
                initialValues={{ newPassword: '', confirmPassword: '' }}
                validationSchema={validationSchema}
                onSubmit={handlePasswordSubmit}
            >
                {({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                        <TextContainer>
                            <Typography variant="h6">Just need a password!</Typography>
                            <Typography variant="subtitle1" color="#959292">Create a password and please confirm it below.</Typography>
                        </TextContainer>
                        <InputContainer>
                            <AppTextInput
                                type={showPassword ? "text" : "password"}
                                label="New Password"
                                name="newPassword"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleShowPassword}>
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <AppTextInput
                                type={showPassword ? "text" : "password"}
                                label="Confirm Password"
                                name="confirmPassword"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleShowPassword}>
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </InputContainer>
                        <ButtonContainer>
                            <AppButton variant="primary" type="submit">Finish</AppButton>
                            <AppButton buttonVariant="outlined" variant="secondary" onClick={prevStep}>Back</AppButton>
                        </ButtonContainer>
                    </Form>
                )}
            </Formik>
            <AppDialog
                open={openSuccess}
                title="Password Created!"
                handleConfirm={handleFinish}
                confirmText="Finish"
            >
                <SuccessAnimation />
                <Typography variant="subtitle2">
                    Your password has been successfully created. Please sign in!
                </Typography>
            </AppDialog>
        </>

    );
}

export default RegisterConPassword;
