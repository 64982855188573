import React from 'react';
import styled from "@emotion/styled";
import {Accordion, AccordionSummary, AccordionDetails, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const StyledAccordionSummary = styled(AccordionSummary)({
    '&.Mui-expanded': {
        margin: 0,
    },
});

const StyledAccordionDetails = styled(AccordionDetails)({
    padding: '0.25em',
});

const StyledAccordion = styled(Accordion)({
    border: '1px solid #37589D',
    borderRadius: 7,
    boxShadow: 'none',
    '&.Mui-expanded': {
        margin: '0.1em',
        borderColor: '#4EB6B5',
    },
    '&:first-of-type': {
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
    },
    '&:last-of-type': {
        borderBottomLeftRadius: 7,
        borderBottomRightRadius: 7,
    },
});

function AppAccordion({title, children, iconColor,...props}) {
    return (
            <StyledAccordion {...props}>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon color={iconColor} />}>
                    <Typography fontWeight="600">
                        {title}
                    </Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                        {children}
                </StyledAccordionDetails>
            </StyledAccordion>
    );
}

export default AppAccordion;

