import React from 'react';
import {Box, IconButton, Typography, useMediaQuery} from "@mui/material";
import styled from '@emotion/styled';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {useNavigate} from "react-router-dom";

const HeaderBox = styled(Box)({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '1.5em',
    marginBottom: '0.5em',
});

const CenterTitle = styled(Typography)({
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#37589D',
    fontWeight: '700',
    maxWidth: '70%',
    whiteSpace: 'nowrap',
    '@media (max-width: 389px)': {
        textAlign: 'center',
        whiteSpace: 'normal',
        overflow: 'visible',
    },
});

const BlackIconButton = styled(IconButton)({
    color: '#37589D',
});

const RightText = styled(Typography)({
    color: '#37589D',
    cursor: 'pointer',
    fontWeight: '700',
});

function AppHeader({title, rightIcon, rightText, onClickRight, backButtonRoute, onBack}) {
    const isSmallScreen = useMediaQuery('(max-width:339px)');
    const fontSize = isSmallScreen ? '1em' : '1.125em';
    const navigate = useNavigate();
    const goBack = () => {
        navigate(backButtonRoute);
    }

    return (
        <HeaderBox>
            <BlackIconButton onClick={onBack ? onBack : goBack}>
                <ArrowBackIosIcon />
            </BlackIconButton>
            <CenterTitle component="div" fontSize={fontSize}>{title}</CenterTitle>
            {rightIcon || rightText ? (
                <>
                    {rightIcon && (
                        <BlackIconButton onClick={onClickRight}>{rightIcon}</BlackIconButton>
                    )}
                    {rightText && (
                        <RightText onClick={onClickRight}>{rightText}</RightText>
                    )}
                </>
            ) : (
                <Box width={0} />
            )}
        </HeaderBox>
    );
}

export default AppHeader;
