import React, {createContext, useContext, useState, useEffect, useCallback} from 'react';
import {useApi} from "./ApiProvider";

const UserContext = createContext({});

export default function UserProvider({ children }) {
    const [user, setUser] = useState();
    const [isContractor, setIsContractor] = useState(false);
    const api = useApi();

    useEffect(() => {
        (async () => {
            if (api.isAuthenticated()) {
                try {
                    const response = await api.get('/me');
                    if (response.ok) {
                        setUser(response.body);
                        setIsContractor(response.body.role !== 0);
                    } else {
                        setUser(null);
                        setIsContractor(false);
                    }
                } catch (error) {
                    setUser(null);
                    setIsContractor(false);
                }
            } else {
                setUser(null);
                setIsContractor(false);
            }
        })();
    }, [api]);

    const login = useCallback(async (email, password) => {
        const result = await api.login(email, password);
        if (result === 'ok') {
            const response = await api.get('/me');
            const userData = response.ok ? response.body : null;
            setUser(userData);
            setIsContractor(userData && userData.role !== 0);
        }
        return result;
    }, [api]);

    const logout = useCallback(async () => {
        await api.logout();
        setUser(null);
        setIsContractor(false);
    }, [api]);

    return (
        <UserContext.Provider value={{ user, setUser, isContractor, login, logout }}>
            {children}
        </UserContext.Provider>
    );
}

export function useUser() {
    return useContext(UserContext);
}
