const RemoteAccessIcon = ({ color = '#000000', size = 800 }) => (
    <svg
        width={`${size}px`}
        height={`${size}px`}
        viewBox="0 0 192 192"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
    >
        <path
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="12"
            d="M96 170c40.869 0 74-33.131 74-74 0-40.87-33.131-74-74-74-40.87 0-74 33.13-74 74 0 40.869 33.13 74 74 74Z"
        />
        <path
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="12"
            d="M126 52 98 80l28 28M66 84l28 28-28 28"
        />
    </svg>
);

export default RemoteAccessIcon;
