export const convertAndDetermineColor = (value, isMetric, specialCheck = false) => {
    let convertedValue;
    let valueToCheck;
    if (isMetric) {
        convertedValue = ((parseFloat(value) - 32) * 5/9).toFixed(2);
        valueToCheck = specialCheck ? convertedValue : parseFloat(value);
    } else {
        convertedValue = parseFloat(value);
        valueToCheck = specialCheck ? parseFloat(value) : parseFloat(value);
    }
    let color;
    if (specialCheck) {
        color = valueToCheck >= -40 && valueToCheck <= (isMetric ? 70 : 158) ? '#46B67D' : '#B1494A';
    } else {
        color = valueToCheck >= -40 && valueToCheck <= (isMetric ? 94 : 200) ? '#46B67D' : '#B1494A';
    }
    return {
        convertedValue: convertedValue + (isMetric ? ' °C' : ' °F'),
        color
    };
};
