import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUnits } from '../contexts/UnitProvider';
import AppContainer from '../components/shared/AppContainer';
import {
    Card,
    Grid,
    Typography,
    TextField,
    Box,
    IconButton,
    FormControlLabel, Checkbox,
} from '@mui/material';
import AppHeader from '../components/shared/AppHeader';
import AppSearchBar from '../components/shared/AppSearchBar';
import SearchIcon from '@mui/icons-material/Search';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import AppDrawer from "../components/shared/AppDrawer";
import { CreateGroupContent } from "../components/unitSpecific/UnitDrawerContent";
import Divider from '@mui/material/Divider';
import UnitCard from '../components/unitSpecific/UnitCard';
import {useUser} from "../contexts/UserProvider";
import {useApi} from "../contexts/ApiProvider";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

const LOCAL_STORAGE_KEY = 'groupOrder';


function MyGroupsScreen() {
    const { unitGroups, saveGroup, units, fetchUnitGroups } = useUnits();
    const {isContractor} = useUser();
    const api = useApi();

    const [searchGroupTerm, setSearchGroupTerm] = useState('');
    const [filteredGroups, setFilteredGroups] = useState([]);
    const [orderedGroups, setOrderedGroups] = useState([]);

    const [createGroupDrawerOpen, setCreateGroupDrawerOpen] = useState(false);

    const [groupName, setGroupName] = useState('');
    const [newGroupName, setNewGroupName] = useState('');
    const [isEditingGroupName, setIsEditingGroupName] = useState(false);

    const [selectedGroupUnits, setSelectedGroupUnits] = useState([]);

    const [groupUnitsDrawerOpen, setGroupUnitsDrawerOpen] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);

    const [unitsAvailable, setUnitsAvailable] = useState([]);
    const [selectedAvailableUnits, setSelectedAvailableUnits] = useState([]);
    const [addingUnits, setAddingUnits] = useState(false);

    const [filteredCreateGroupUnits, setFilteredCreateGroupUnits] = useState(units);



    const navigate = useNavigate();
    // console.log('Groups screen rendered...');
    console.log(unitGroups);

    useEffect(() => {
        fetchUnitGroups();
    }, []);


    useEffect(() => {
        const savedOrder = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));

        if (savedOrder) {
            // Create the ordered list of groups
            const ordered = savedOrder
                .map(id => unitGroups.find(group => group.id === id))
                .filter(Boolean); // Filter out null/undefined values if groups were removed

            // Append any groups that are not in the saved order
            const missingGroups = unitGroups.filter(group => !savedOrder.includes(group.id));
            setOrderedGroups([...ordered, ...missingGroups]);
        } else {
            // If no saved order, just use the fetched groups
            setOrderedGroups(unitGroups);
        }
    }, [unitGroups]);

    useEffect(() => {
        if (searchGroupTerm === '') {
            setFilteredCreateGroupUnits(units);
        } else {
            const term = searchGroupTerm.toLowerCase();
            const filtered = units.filter(unit =>
                unit.serial_number.toLowerCase().includes(term)
            );
            setFilteredCreateGroupUnits(filtered);
        }
    }, [searchGroupTerm, units]);



    const saveOrderToLocalStorage = (newOrder) => {
        const groupIds = newOrder.map(group => group.id);
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(groupIds));
    };

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(orderedGroups);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setOrderedGroups(items);
        saveOrderToLocalStorage(items);
    };


    useEffect(() => {
        setFilteredGroups(orderedGroups);
    }, [orderedGroups]);

    useEffect(() => {
        if (searchGroupTerm === '') {
            setFilteredGroups(orderedGroups);
        } else {
            const term = searchGroupTerm.toLowerCase();
            setFilteredGroups(
                orderedGroups.filter(group => group.name.toLowerCase().includes(term))
            );
        }
    }, [searchGroupTerm, orderedGroups]);


    const handleCreateGroupDrawer = () => {
        setCreateGroupDrawerOpen(!createGroupDrawerOpen);
    };

    const toggleUnitSelection = (unitId) => {
        setSelectedGroupUnits(prevUnits => {
            if (prevUnits.includes(unitId)) {
                return prevUnits.filter(id => id !== unitId);
            } else {
                return [...prevUnits, unitId];
            }
        });
    };

    const handleSaveGroup = async () => {
        const response = await saveGroup(groupName, selectedGroupUnits);

        if (response.ok) {
            console.log('Successfully created new group');

            // Reset local states
            setGroupName('');
            setSelectedGroupUnits([]);
            setCreateGroupDrawerOpen(false);

            // Fetch updated groups after creating the new group
            await fetchUnitGroups(); // This triggers the update of `unitGroups`

        } else {
            console.log('Unable to create new group');
        }
    };

    const handleGroupUnitsDrawer = (group) => {
        setSelectedGroup(group);
        setNewGroupName(group.name);  // Initialize newGroupName with the current group name
        setGroupUnitsDrawerOpen(true);
        setAddingUnits(false); // Start by showing units in the group
    };

    const handleCloseGroupUnitsDrawer = () => {
        setGroupUnitsDrawerOpen(false);
        setSelectedGroup(null);
        setIsEditingGroupName(false);
    };

    const handleEditGroupName = () => {
        setIsEditingGroupName(true);
    };

    const handleChangeName = async (groupId, oldName, newName) => {
        const data = {
            'group_name': oldName,
            'new_name': newName
        };
        const response = await api.put('/me/groups', data);
        if (response.ok) {
            await fetchUnitGroups();
            setSelectedGroup({ ...selectedGroup, name: newName });
        } else {
            console.log('Group name not changed.')
        }
    };

    const handleSaveGroupName = async () => {
        if (newGroupName.trim() !== selectedGroup.name) {
            await handleChangeName(selectedGroup.id, selectedGroup.name, newGroupName);
        }
        setIsEditingGroupName(false);
    };


    const handleDeleteGroup = async (groupId) => {
        const data = {'group_id': groupId};
        const response = await api.delete('/me/groups', data );
        console.log(response)
        console.log(data)
        if (response.ok) {
            await fetchUnitGroups();
            handleCloseGroupUnitsDrawer();
        } else {
            console.log('Can not delete group.')
        }
    };


    const handleAddUnits = () => {
        setUnitsAvailable(units.filter(unit => !selectedGroup.units.some(u => u.id === unit.id))); // Filter units not in the group
        setAddingUnits(true);
    };

    const handleFinishAddUnits = async () => {
        await moveUnits('toGroup', selectedAvailableUnits);
        setAddingUnits(false); // Switch back to group units list after adding
    };

    const handleRemoveUnit = async (unitId) => {
        await moveUnits('fromGroup', [unitId]);
        console.log('Removed unit', unitId);
    }

    const moveUnits = async (direction, unitIds = []) => {
        let unitsToAdd = [];
        let unitsToRemove = [];

        if (direction === 'toGroup') {
            if (unitIds.length === 0) {
                console.log('No units selected to add');
                return;
            }
            unitsToAdd = [...unitIds];

            // Update the selected group with the newly added units
            const movedUnits = unitsAvailable.filter(unit => unitIds.includes(unit.id));
            setSelectedGroup({
                ...selectedGroup,
                units: [...selectedGroup.units, ...movedUnits],
            });

            // Remove the moved units from the available units list
            setUnitsAvailable(unitsAvailable.filter(unit => !unitIds.includes(unit.id)));

        } else if (direction === 'fromGroup') {
            if (unitIds.length === 0) {
                console.log('No units selected to remove');
                return;
            }
            unitsToRemove = [...unitIds];

            // Filter out the removed units from the selected group in state
            const remainingUnits = selectedGroup.units.filter(unit => !unitIds.includes(unit.id));
            setSelectedGroup({
                ...selectedGroup,
                units: remainingUnits,
            });

            // Optionally, you can add the removed units back to available units if desired
            const movedUnits = selectedGroup.units.filter(unit => unitIds.includes(unit.id));
            setUnitsAvailable([...unitsAvailable, ...movedUnits]);
        }

        try {
            const data = {
                'group_name': selectedGroup.name,
                'unit_ids_add': direction === 'toGroup' ? unitsToAdd : [],
                'unit_ids_remove': direction === 'fromGroup' ? unitsToRemove : [],
            };
            const response = await api.put('/me/groups', data);

            if (response.ok) {
                console.log('Group successfully updated');
                fetchUnitGroups(); // Refresh groups after the update
            } else {
                console.log('Failed to update group');
            }
        } catch (error) {
            console.error('An error occurred while updating the group', error);
        }
    };


    const handleToggleUnitSelection = (unitId) => {
        if (selectedAvailableUnits.includes(unitId)) {
            setSelectedAvailableUnits(selectedAvailableUnits.filter(id => id !== unitId));
        } else {
            setSelectedAvailableUnits([...selectedAvailableUnits, unitId]);
        }
    };

    return (
        <AppContainer>
            <AppHeader backButtonRoute="/dashboard/my_units" title="Unit Groups" onClickRight={handleCreateGroupDrawer} rightIcon={
                <CreateNewFolderIcon
                    style={{ cursor: "pointer" }}
                />
            } />

            <AppSearchBar
                value={searchGroupTerm}
                onChange={e => setSearchGroupTerm(e.target.value)}
                placeholder="Search groups..."
                endAdornment={<SearchIcon style={{ color: '#37589D' }} />}
            />

            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="groups">
                    {(provided) => (
                        <Grid container spacing={1} mt={3} {...provided.droppableProps} ref={provided.innerRef}>
                            {filteredGroups.map((group, index) => (
                                <Draggable key={group.id} draggableId={group.id.toString()} index={index}>
                                    {(provided) => (
                                        <Grid item xs={6} sm={6} md={6} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                            <Card variant="outlined" sx={{ borderRadius: '10px' }} onClick={() => handleGroupUnitsDrawer(group)}>
                                                <Box sx={{ padding: '8px', backgroundColor: '#4EB6B5', color: 'white' }}>
                                                    <Typography variant="subtitle2" fontWeight="700">
                                                        {group.name}
                                                    </Typography>
                                                </Box>
                                                <Divider />
                                                <Box sx={{ padding: '8px' }}>
                                                    <Typography variant="body2">
                                                        {group.units.length} unit(s)
                                                    </Typography>
                                                </Box>
                                            </Card>
                                        </Grid>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </Grid>
                    )}
                </Droppable>
            </DragDropContext>

            {/*For creating a new group*/}
            <AppDrawer open={createGroupDrawerOpen} onClose={handleCreateGroupDrawer} title="Create a new group">
                <CreateGroupContent
                    groupName={groupName}
                    setGroupName={setGroupName}
                    filteredGroupUnits={filteredCreateGroupUnits}
                    selectedGroupUnits={selectedGroupUnits}
                    toggleUnitSelection={toggleUnitSelection}
                    handleSaveGroup={handleSaveGroup}
                    setSearchGroupTerm={setSearchGroupTerm}
                    searchGroupTerm={searchGroupTerm}
                />
            </AppDrawer>

            {/*For opening a group*/}
            <AppDrawer
                open={groupUnitsDrawerOpen}
                onClose={handleCloseGroupUnitsDrawer}
                title={
                    <Box display="flex" alignItems="center" gap={1}>
                        {addingUnits ? (
                            <>
                                <IconButton
                                    color="info"
                                    onClick={() => setAddingUnits(false)}
                                >
                                    <ArrowBackIosNewIcon />
                                </IconButton>
                            </>
                        ) : (
                            <>
                                <IconButton
                                    onClick={isEditingGroupName ? handleSaveGroupName : handleEditGroupName}
                                    aria-label={isEditingGroupName ? "Save Group Name" : "Edit Group Name"}
                                >
                                    {isEditingGroupName ? <CheckIcon fontSize="small" /> : <EditIcon fontSize="small" />}
                                </IconButton>
                                {isEditingGroupName ? (
                                    <TextField
                                        value={newGroupName}
                                        onChange={e => setNewGroupName(e.target.value)}
                                        variant="standard"
                                        fullWidth
                                    />
                                ) : (
                                    <Typography variant="subtitle1" fontWeight="700" flex={1}>
                                        Units in {selectedGroup?.name}
                                    </Typography>
                                )}
                            </>
                        )}
                    </Box>
                }
            >
                {!addingUnits ? (
                    <>
                        {selectedGroup && selectedGroup.units.length > 0 ? (
                            <Box display="flex" flexDirection="column" gap={1} mt={1} maxHeight="60%"  overflow="auto">
                                {selectedGroup.units.map(unit => (
                                    <UnitCard
                                        key={unit.id}
                                        unit={unit}
                                        contractor={isContractor}
                                        navigate={navigate}
                                        source="groups"
                                        setDrawerOpen={handleCloseGroupUnitsDrawer}
                                        swipeIcon={<DeleteIcon />}
                                        onSwipe={() => handleRemoveUnit(unit.id)}
                                    />
                                ))}
                            </Box>
                        ) : (
                            <Typography>No units in this group.</Typography>
                        )}

                        <Grid container alignItems="center" justifyContent="space-between" mt={1}>
                            {/* Add Units Button */}
                            <Grid item>
                                <IconButton color="success" onClick={handleAddUnits}>
                                    <AddIcon />
                                    <Typography variant="body2">
                                        Add Unit
                                    </Typography>
                                </IconButton>
                            </Grid>
                            {/* Delete Group Button */}
                            <Grid item>
                                <IconButton color="error" onClick={() => handleDeleteGroup(selectedGroup.id)}>
                                    <DeleteIcon />
                                    <Typography variant="body2" >
                                        Delete Group
                                    </Typography>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <>
                        {/* Show available units to add */}
                        <Typography variant="subtitle1">Select Units to Add:</Typography>

                        {unitsAvailable.length === 0 ? (
                            <Typography mt={1}  textAlign="center" variant="h6">No units available.</Typography>
                        ) : (
                            <Box mt={1}>
                                {unitsAvailable.map(unit => (
                                    <Box key={unit.id}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={selectedAvailableUnits.includes(unit.id)}
                                                    onChange={() => handleToggleUnitSelection(unit.id)}
                                                    size="small"
                                                    color="success"
                                                />
                                            }
                                            label={`SN: ${unit.serial_number}`}
                                            sx={{
                                                '.MuiFormControlLabel-label': {
                                                    fontSize: '0.9em',
                                                },
                                            }}
                                        />
                                        <Divider />
                                    </Box>
                                ))}
                            </Box>
                        )}
                        {selectedAvailableUnits.length > 0 && (
                            <IconButton
                                color="success"
                                onClick={handleFinishAddUnits}
                                sx={{ marginTop: '16px' }}
                            >
                                <CheckIcon />
                                <Typography variant="body2" ml={1}>
                                    Finish adding
                                </Typography>
                            </IconButton>
                        )}
                    </>
                )}
            </AppDrawer>

        </AppContainer>
    );
}

export default MyGroupsScreen;
