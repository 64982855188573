import React from 'react';
import { Box, Typography, useMediaQuery } from "@mui/material";
import styled from "@emotion/styled";
import AppCard from "../shared/AppCard";
import { convertAndDetermineColor } from "../../utils/TempUtils";
import TimelineIcon from "@mui/icons-material/Timeline";
import { useNavigate } from "react-router-dom";

// Styled Components
const CardContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
});

const UnitInfo = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '1em',
    marginTop: '1em',
});

const InvertedCard = styled(AppCard)({
    backgroundColor: '#37589D',
    color: 'white',
    borderColor: 'transparent',
});

const AppCardExtra = styled(AppCard)({
    '& .MuiCardContent-root:last-child': {
        padding: 15,
    },
});

const TempRow = ({ label, value, color, fontSize, showIcon, onIconClick }) => (
    <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
        <Box>
            <Typography fontWeight="500" style={{ fontSize }}>
                {label}
            </Typography>
        </Box>
        <Box display="flex" alignItems="center">
            <Typography fontWeight="700" style={{ fontSize, marginRight: '1em', color }}>
                {value}
            </Typography>
            {showIcon && onIconClick ? (
                <Box ml={2}>
                    <TimelineIcon onClick={onIconClick} style={{ color: '#4EB6B5', cursor: 'pointer' }} />
                </Box>
            ) : (
                <Box ml={2} width="24px" />
            )}
        </Box>
    </Box>
);

function UnitStatus({ unitData, isMetric, contractor, unitType }) {
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery('(max-width:390px)');
    const isVerySmallScreen = useMediaQuery('(max-width:359px)');
    const fontSize = isVerySmallScreen ? '0.85em' : isSmallScreen ? '0.9em' : '1em';

    const handlePlotIcon = (path, title) => {
        navigate(`${path}?title=${title}`);
    };

    const midTankTemp = parseFloat(unitData[20][0]);
    const isMidTankValid = !isNaN(midTankTemp) && midTankTemp !== -40;

    return (
        <UnitInfo>
            <CardContainer>
                <Typography mb={0.5} fontWeight="600" color="#959292">Condition:</Typography>
                <InvertedCard>
                    {unitType === 'GAHP' && (
                        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                            <Box>
                                <Typography fontWeight="600" style={{ fontSize }}>Status</Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Typography fontWeight="700" style={{ fontSize }}>
                                    {unitData[67][0].replace('GAHP', '')}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                    {/* Operating Mode */}
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Box>
                            <Typography fontWeight="600" style={{ fontSize }}>Operating Mode</Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Typography fontWeight="700" style={{ fontSize }}>
                                {unitType === 'GAHP' ? unitData[66][0].replace('GAHP', '') : unitData[65][0].replace('AHU', '')}
                            </Typography>
                        </Box>
                    </Box>
                    {/* Blower Status for AHU */}
                    {unitType === 'AHU' && (
                        <Box display="flex" alignItems="center" justifyContent="space-between" mt={2}>
                            <Box>
                                <Typography fontWeight="600" style={{ fontSize }}>Blower Status</Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Typography fontWeight="700" style={{ fontSize }}>
                                    {unitData[57][0]}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </InvertedCard>
            </CardContainer>

            <CardContainer>
                <Typography mb={0.5} fontWeight="700" color="#959292">Temperatures:</Typography>
                <AppCardExtra>
                    {unitType === 'GAHP' ? (
                        <>
                            <TempRow
                                label={contractor ? 'Ambient' : 'Outdoor Air'}
                                value={(() => {
                                    const { convertedValue, color } = convertAndDetermineColor(unitData[10][0], isMetric, true);
                                    return convertedValue;
                                })()}
                                color={(() => {
                                    const { color } = convertAndDetermineColor(unitData[10][0], isMetric, true);
                                    return color;
                                })()}
                                fontSize={fontSize}
                                showIcon={contractor}
                                onIconClick={() => handlePlotIcon('plot', 'Ambient')}
                            />

                            <TempRow
                                label="Hydronic Supply"
                                value={(() => {
                                    const { convertedValue, color } = convertAndDetermineColor(unitData[12][0], isMetric);
                                    return convertedValue;
                                })()}
                                color={(() => {
                                    const { color } = convertAndDetermineColor(unitData[12][0], isMetric);
                                    return color;
                                })()}
                                fontSize={fontSize}
                                showIcon={contractor}
                                onIconClick={() => handlePlotIcon('plot', 'Hydronic Supply')}
                            />

                            {contractor && (
                                <TempRow
                                    label="Hydronic Return"
                                    value={(() => {
                                        const { convertedValue, color } = convertAndDetermineColor(unitData[16][0], isMetric);
                                        return convertedValue;
                                    })()}
                                    color={(() => {
                                        const { color } = convertAndDetermineColor(unitData[16][0], isMetric);
                                        return color;
                                    })()}
                                    fontSize={fontSize}
                                    showIcon={contractor}
                                    onIconClick={() => handlePlotIcon('plot', 'Hydronic Return')}
                                />
                            )}

                            {isMidTankValid && (
                                <TempRow
                                    label="Mid Tank"
                                    value={(() => {
                                        const { convertedValue, color } = convertAndDetermineColor(unitData[20][0], isMetric);
                                        return convertedValue;
                                    })()}
                                    color={(() => {
                                        const { color } = convertAndDetermineColor(unitData[20][0], isMetric);
                                        return color;
                                    })()}
                                    fontSize={fontSize}
                                    showIcon={contractor}
                                    onIconClick={() => handlePlotIcon('plot', 'Mid Tank')}
                                />
                            )}

                            {/* Hydronic Flow */}
                            <TempRow
                                label="Hydronic Flow"
                                value={`${unitData[17][0]} GPM`}
                                fontSize={fontSize}
                                showIcon={contractor}
                                onIconClick={() => handlePlotIcon('plot', 'Hydronic Flow')}
                            />
                        </>
                    ) : (
                        /* AHU Unit Type */
                        <TempRow
                            label="Supply Air"
                            value={(() => {
                                const { convertedValue, color } = convertAndDetermineColor(unitData[42][0], isMetric);
                                return convertedValue;
                            })()}
                            color={(() => {
                                const { color } = convertAndDetermineColor(unitData[42][0], isMetric);
                                return color;
                            })()}
                            fontSize={fontSize}
                            showIcon={contractor}
                            onIconClick={() => handlePlotIcon('plot', 'Supply Air')}
                        />
                    )}
                </AppCardExtra>
            </CardContainer>
        </UnitInfo>
    );
}

export default UnitStatus;
