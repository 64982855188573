import { motion } from "framer-motion";

const pageVariants = {
    initial: {
        opacity: 0,
        x: '-100vw'
    },
    in: {
        opacity: 1,
        x: 0
    },
    out: {
        opacity: 0,
        x: '100vw'
    }
};

const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 0.25
};

const AppTransition = ({ children }) => (
    <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={pageTransition}
    >
        {children}
    </motion.div>
)

export default AppTransition;
