import React, {useState} from 'react';
import AppContainer from "../shared/AppContainer";
import styled from '@emotion/styled';
import {Box, FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import AppButton from "../shared/AppButton";
import AppHeader from "../shared/AppHeader";

const Title = styled(Box)({
   display: 'flex',
    fontFamily: 'Jura',
    fontWeight: 700,
    fontSize: '1.5em',
    justifyContent: "center",
    marginBottom: '1em',
    marginTop: '1em',
    color: '#959292',
    '@media (max-width: 359px)': {
       fontSize: '1.25rem',
    },
});

const RadioContainer = styled(Box)({
   display: 'flex',
    margin: '1em',
});

const StyledFormControlLabel = styled(FormControlLabel)({
    '& .MuiTypography-root': {
        fontWeight: '600',
        color: 'grey',
        fontSize: '1.2em',
    },
    '@media (max-width: 359px)': {
        fontSize: '0.9rem',
    },
});

const CustomRadio = styled(Radio)({
    color: '#37589D',
    '&.Mui-checked': {
        color: '#4EB6B5',
    },
});

const applicationMapping = {
    'Forced-Air (w/Anesi AHU)': 0,
    'Hydronic (radiant floor)': 1,
    'Commercial water heating': 2,
    'Pool heating': 3,
    'Ice melt': 4,
};

function AddUnitOne(props) {
    const [selected, setSelected] = useState('');
    const handleChange = (ev) => {
        setSelected(ev.target.value);
        props.onChoiceChange(applicationMapping[ev.target.value]);
    };

    return (
        <AppContainer style = {{ width: '100%' }}>
            <AppHeader title="Add Unit" backButtonRoute={`/dashboard/my_units`} />
                <Title>
                    Choose the Application
                </Title>
                <RadioContainer>
                    <FormControl>
                        <RadioGroup onChange={handleChange}>
                            <StyledFormControlLabel value="Forced-Air (w/Anesi AHU)" control={<CustomRadio />} label="Forced-Air (w/Anesi AHU)" />
                            <StyledFormControlLabel value="Commercial water heating" control={<CustomRadio />} label="Commercial water heating" />
                            <StyledFormControlLabel disabled={true} value="Hydronic (radiant floor)" control={<CustomRadio />} label="Hydronic (radiant floor)" />
                            <StyledFormControlLabel disabled={true} value="Pool heating" control={<CustomRadio />} label="Pool heating" />
                            <StyledFormControlLabel disabled={true} value="Ice melt" control={<CustomRadio />} label="Ice melt" />
                        </RadioGroup>
                    </FormControl>
                </RadioContainer>
            <AppButton onClick={props.onNext} disabled={!selected}>
                Continue
            </AppButton>
        </AppContainer>
    );
}

export default AddUnitOne;
