import React, {useContext, createContext, useCallback, useMemo} from 'react';
import SMTIApiClient from "../services/SMTIApiConfig";
import {useAlert} from "./AlertsProvider";

const ApiContext = createContext();
function ApiProvider({children}) {
    const alert = useAlert();

    const onError = useCallback(() => {
        console.log('Oops! No access or error. Please Retry');
    }, []);

    const api = useMemo(() => new SMTIApiClient(onError), [onError]);

    return (
        <ApiContext.Provider value={api}>
            {children}
        </ApiContext.Provider>
    );
}
export default ApiProvider;

export function useApi() {
    return useContext(ApiContext);
}
