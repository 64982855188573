import React from "react";
import { ThemeProvider } from "@mui/material";
import brandTheme from "./utils/BrandConfig";
import { BrowserRouter } from "react-router-dom";
import ApiProvider from "./contexts/ApiProvider";
import UserProvider from "./contexts/UserProvider";
import AlertsProvider from "./contexts/AlertsProvider";
import { UnitProvider } from "./contexts/UnitProvider";
import AppRoutes from "./services/AppRoutes";

function App() {
    return (
        <ThemeProvider theme={brandTheme}>
            <BrowserRouter>
                <AlertsProvider>
                    <ApiProvider>
                        <UserProvider>
                            <UnitProvider>
                                <AppRoutes />
                            </UnitProvider>
                        </UserProvider>
                    </ApiProvider>
                </AlertsProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
