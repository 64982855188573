import React, {useEffect, useState} from 'react';
import AppContainer from "../components/shared/AppContainer";
import AppHeader from "../components/shared/AppHeader";
import AppCard from "../components/shared/AppCard";
import {useLocation, useNavigate} from "react-router-dom";
import {useUnits} from "../contexts/UnitProvider";
import {
    Box,
    Typography,
    TextField,
    InputAdornment,
    IconButton,
    Card, CardContent, Collapse, CardHeader, useMediaQuery, Stack, MenuItem, Menu, Divider
} from "@mui/material";
import styled from "@emotion/styled";
import AddBoxIcon from '@mui/icons-material/AddBox';
import {useAlert} from "../contexts/AlertsProvider";
import SearchIcon from '@mui/icons-material/Search';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import CheckIcon from '@mui/icons-material/Check';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import WarningIcon from '@mui/icons-material/Warning';
import FilterListIcon from '@mui/icons-material/FilterList';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AppLoader from "../components/shared/AppLoader";
import {useUser} from "../contexts/UserProvider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AppDrawer from "../components/shared/AppDrawer";
import {UnitInfoContent} from "../components/unitSpecific/UnitDrawerContent";
import UnitCard from "../components/unitSpecific/UnitCard";
import {useApi} from "../contexts/ApiProvider";

const MainContainer = styled(Box) ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '1em',
});

const StyledInvCard = styled(AppCard)(({ faultInUnits }) => ({
    '& .MuiCardContent-root:last-child': {
        padding: 5,
    },
    backgroundColor: faultInUnits ? '#B1494A' : '#4EB6B5',
    border: 'none',
    color: '#FFFFFF',
    marginTop: '1em',
    marginBottom: '0.5em',
}));

const ContractorCard = styled(Card)({
    backgroundColor: '#37589D',
    color: 'white',
    marginTop: '1em',
    borderRadius: '10px',
});

// Use custom component
const StyledSearchInput = styled(TextField)({
    marginTop: '2em',
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#37589D',
            borderWidth: 1,
            borderRadius: 7,
        },
        '&.Mui-focused fieldset': {
            borderColor: '#37589D',
        },
        '& .MuiInputBase-input': {
            height: '2.5em',
            padding: '0 1em',
        },
    },
});

const GradientCard = styled(Card)({
    background: 'linear-gradient(to right, #37589D, #4EB6B5)',
    color: 'white',
    borderRadius: '10px',
    padding: '1em',
    margin: '1em 0',
    cursor: 'pointer',
    boxShadow: 'none'
});


function MyUnitsScreen(props) {
    const isSmallScreen = useMediaQuery('(max-width:359px)');
    const navigate = useNavigate();
    const location = useLocation();
    const {user, isContractor} = useUser();
    const alert = useAlert();
    const api = useApi();
    const {units, fetchAllUnits, fetchUnitGroups} = useUnits();
    const [hasUnits, setHasUnits] = useState(false);
    const [hasUnitGroups, setHasUnitGroups] = useState(false);
    const [faultInUnits, setFaultInUnits] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredUnits, setFilteredUnits] = useState([]);
    const [selectedUnit, setSelectedUnit] = useState(null);

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [cardExpanded, setCardExpanded] = useState(false);

    const [filterAnchorEl, setFilterAnchorEl] = useState(null);  // For MoreVert menu
    const [unitFilter, setUnitFilter] = useState('all');  // For filtering units

    const fullAddress = selectedUnit && selectedUnit.users
        ? (() => {
            const owner = selectedUnit.users.find(user => user.role === 0);
            return owner && owner.addresses && owner.addresses[0]
                ? `${owner.addresses[0].address_line_1}, ${owner.addresses[0].address_line_2}, ${owner.addresses[0].city}, ${owner.addresses[0].state_abbreviation} ${owner.addresses[0].zipcode}`
                : 'Not available';
        })()
        : 'Not available';
    const installLocationAddress = selectedUnit && selectedUnit.install_location_id
        ? `${selectedUnit.install_location.address_line_1}, ${selectedUnit.install_location.address_line_2 ? selectedUnit.install_location.address_line_2 + ', ' : ''}${selectedUnit.install_location.city}, ${selectedUnit.install_location.state_abbreviation} ${selectedUnit.install_location.zipcode}`
        : 'Not Available';

    const handleDrawer = () => {setDrawerOpen(!drawerOpen)};

    const handleToolTip = () => {setTooltipOpen(!tooltipOpen)};
    const handleExpandClick = () => {setCardExpanded(!cardExpanded)};

    const handleFilterClick = (event) => {
        setFilterAnchorEl(event.currentTarget);
    };

    const handleFilterClose = () => {
        setFilterAnchorEl(null);
    };

    const handleFilterSelection = (filter) => {
        setUnitFilter(filter);
        handleFilterClose();
    };

    // same with this, see below
    useEffect(() => {
        const fetchUnits = async () => {
            setLoading(true);
            const response = await fetchAllUnits();
            if(response.ok) {
                if (response.body && response.body.length > 0) {
                    setHasUnits(true);
                }
            } else {
                navigate('/dashboard');
                alert('Enable to fetch units at this time', 'error');
            }
            setLoading(false);
        };
        fetchUnits();
    }, []);

    // This may be redundant, if fetchunitgroups is auto called when file mounts from unit provider
    const fetchGroups = async () => {
        setLoading(true);
        const response = await fetchUnitGroups();
        if (response.ok) {
            if (response.body && response.body.length > 0) {
                setHasUnitGroups(true);
            }
        } else if (response.status === 404) {
            setHasUnitGroups(false);
        } else {
            setHasUnitGroups(false);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchGroups();
    }, []);

    useEffect(() => {
        if (location.state) {
            if (location.state.filter) {
                setUnitFilter(location.state.filter);
            }

            if (location.state.expandCard) {
                setCardExpanded(true);
            } else {
                setCardExpanded(false);
            }
        }
    }, [location.state]);

    useEffect(() => {
        if (Array.isArray(units)) {
            const anyFaults = units.some(unit => Array.isArray(unit.faults) && unit.faults.length > 0);
            setFaultInUnits(anyFaults);
            // filter and search can be combined...
            const term = searchTerm.toLowerCase();
            const filtered = units.filter(unit => {
                const matchesSerialNumber = unit.serial_number.toLowerCase().includes(term);
                const matchesName = unit.name_customer && unit.name_customer.toLowerCase().includes(term);
                const matchesFilter = unitFilter === 'all'
                    || (unitFilter === 'inFault' && unit.faults && unit.faults.length > 0)
                    || (unitFilter === 'operational' && (!unit.faults || unit.faults.length === 0));

                return (matchesSerialNumber || matchesName) && matchesFilter;
            });

            setFilteredUnits(filtered);
            setCardExpanded(searchTerm !== '' || location.state?.expandCard === true);
        }
    }, [searchTerm, units, unitFilter, location.state]);


    const ExpandMore = styled((props) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
    })(({ theme, expand }) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }));

    // Find better way to do this. Does not re-fetch on page refresh.
    const getUniqueContractors = (units) => {
        const uniqueContractors = new Set();
        const contractors = [];

        units.forEach(unit => {
            const contractor = unit.users.find(user => user.role !== 0);
            if (contractor && !uniqueContractors.has(contractor.id)) {
                uniqueContractors.add(contractor.id);
                contractors.push(contractor);
            }
        });
        return contractors;
    };

    // this can be moved to unitsProvider, same on other files
    const handleRemoveUnit = async (unitId) => {
        const data = {units: [unitId]}
        const response = await api.delete('/me/units', data );
        if (response.ok) {
            console.log(`Unit with ID ${unitId} successfully deleted.`);
            await fetchAllUnits();
        } else {
            console.error('Failed to delete the unit.');
        }
    };

    if (loading) {
        return (
            <AppContainer justifyContent="center">
                <AppLoader />
            </AppContainer>
        );
    }

    return (
        <AppContainer>
            <AppHeader backButtonRoute={`/dashboard`} title="Installed Units" rightIcon={<AddBoxIcon />} onClickRight={() => navigate(`add_unit`)} />
            {hasUnits ? (
                <>
                <Box style={{ textAlign: 'center' }} mt={1} display="flex" flexDirection="column" alignItems="center" >
                    <Typography >
                        {isContractor ? 'Select a unit for more data.' : 'Choose your unit for more information.'}
                    </Typography>
                    {isContractor && (
                        <>
                            <Box display="flex" alignItems="flex-end" justifyContent="center" width="100%">
                                <Box flex={1} maxWidth="5em">
                                    <hr />
                                </Box>
                                <Typography fontWeight="700" mx={1} style={{ color: '#37589D' }}>
                                    or
                                </Typography>
                                <Box flex={1} maxWidth="5em">
                                    <hr />
                                </Box>
                            </Box>
                            <Typography >
                                Quick scan with Info Icon.
                            </Typography>
                        </>
                    )}
                </Box>
                    {/*Can be its own component, below */}
                <StyledSearchInput
                    variant="outlined"
                    placeholder="Search units..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon  style={{ color: '#37589D' }} />
                            </InputAdornment>
                        ),
                    }}
                />
                <StyledInvCard faultInUnits={faultInUnits}>
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                        {faultInUnits ? (
                            <>
                                <Typography ml={1} mr={2} fontSize={isSmallScreen? "0.9em" : "1.125em"} fontWeight="700">Some units need attention</Typography>
                                <WarningIcon />
                            </>
                        ) : (
                            <>
                                <Typography ml={1} mr={2} fontSize={isSmallScreen? "0.9em" : "1.125em"} fontWeight="700">Good news, all units are OK</Typography>
                                <CheckIcon />
                            </>
                        )}
                    </Box>
                </StyledInvCard>
                <Card variant="outlined" style={{ borderRadius: '10px', marginTop: '0.5em', position: 'relative', backgroundColor: cardExpanded ? 'white' : '#FCFCFF' }}>
                    <CardHeader style={{ padding: '11px' }}
                        avatar={
                            <Inventory2Icon style={{ color: '#4EB6B5', fontSize: '1.5em' }} />
                        }
                        title={
                            <Typography fontWeight="700" color="#959292">
                                {isContractor ? 'All Units' : 'My Units'}
                            </Typography>
                        }
                        subheader="Associated with me"
                        action={
                            <>
                                <ExpandMore
                                    expand={cardExpanded}
                                    onClick={handleExpandClick}
                                    aria-expanded={cardExpanded}
                                    aria-label="show more"
                                >
                                    <ExpandMoreIcon />
                                </ExpandMore>
                                <IconButton sx={{ padding: 0 }} onClick={handleFilterClick}>
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    anchorEl={filterAnchorEl}
                                    open={Boolean(filterAnchorEl)}
                                    onClose={handleFilterClose}
                                    sx={{
                                        '& .MuiPaper-root': {
                                            borderRadius: '16px',
                                            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                                            overflow: 'hidden',
                                        },
                                        '& .MuiMenuItem-root': {
                                            fontSize: '14px',
                                            fontWeight: '600',
                                            padding: '4px 10px',
                                            '&:hover': {
                                                backgroundColor: '#e0e0e5',
                                            },
                                        },
                                        '& .MuiDivider-root': {
                                            backgroundColor: 'ghostwhite',
                                            margin: 0,
                                        },
                                    }}
                                >
                                    <MenuItem
                                        onClick={() => handleFilterSelection('all')}
                                        sx={{
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        All
                                        <FilterListIcon color="secondary" fontSize="small" sx={{ ml: 1 }} />
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem
                                        onClick={() => handleFilterSelection('inFault')}
                                        sx={{
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        In Fault
                                        <ErrorIcon fontSize="small" sx={{ ml: 1 }} color="error" />
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem
                                        onClick={() => handleFilterSelection('operational')}
                                        sx={{
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        Operational
                                        <CheckCircleIcon fontSize="small" sx={{ ml: 1 }} color="success" />
                                    </MenuItem>
                                </Menu>

                            </>
                        }
                    />
                    <Collapse in={cardExpanded} timeout="auto" unmountOnExit>
                        <CardContent style={{ maxHeight: '12em', overflowY: 'auto'}}>
                            <MainContainer>
                                {filteredUnits && filteredUnits.map((unit) => (
                                    <UnitCard
                                        key={unit.id}
                                        unit={unit}
                                        contractor={isContractor}
                                        navigate={navigate}
                                        source="units"
                                        showInfo={true}
                                        setDrawerOpen={setDrawerOpen}
                                        setSelectedUnit={setSelectedUnit}
                                        fetchAllUnits={fetchAllUnits}

                                        onInfoClick={() => {
                                            setSelectedUnit(unit);
                                            setDrawerOpen(true);
                                        }}
                                        onSwipe={() => handleRemoveUnit(unit.id)}
                                    />
                                ))}
                            </MainContainer>
                        </CardContent>
                    </Collapse>
                </Card>
                {!isContractor && (
                    <ContractorCard>
                        <CardHeader
                            title={
                                <Typography fontWeight="700" color="white">
                                    My Contractors
                                </Typography >
                            }
                        />
                        <CardContent>
                            <Stack spacing={1}>
                                {getUniqueContractors(filteredUnits).map((contractor) => (
                                    <Box key={contractor.id}>
                                        <Typography fontWeight="600">
                                            {contractor.first_name} {contractor.last_name}
                                        </Typography>
                                        <Typography>
                                            Phone: {contractor.phone_numbers.length > 0 ? contractor.phone_numbers[0].number : 'N/A'}
                                        </Typography>
                                        <Typography>
                                            Email: {contractor.email}
                                        </Typography>
                                    </Box>
                                ))}
                            </Stack>
                        </CardContent>
                    </ContractorCard>
                )}
                {hasUnitGroups ? (
                    <GradientCard onClick={() => navigate('groups')}>
                        <Box display="flex" alignItems="center" gap="1em">
                            <WorkspacesIcon />
                            <Box>
                                <Typography fontWeight="700">
                                    My Groups
                                </Typography>
                                <Typography fontSize="0.9em">
                                    Manage and organize your  groups
                                </Typography>
                            </Box>
                        </Box>
                    </GradientCard>
                ) : (
                    isContractor && (
                        <Typography textAlign="center" mt={1} color="#959292" fontWeight="700">
                            {loading ? 'Loading groups...' : 'No units are grouped yet...'}
                        </Typography>
                    )
                )}
                </>
            ) : (
                <Typography textAlign="center" color="#959292" fontWeight="700" fontSize="1.25em">
                    No units are currently installed, click the '+' in the upper right to add.
                </Typography>
            )}

            <AppDrawer open={drawerOpen} onClose={handleDrawer} title="Quick Info">
                <UnitInfoContent
                    selectedUnit={selectedUnit}
                    loggedInUser={{ id: user.id, role: user.role }}
                    handleToolTip={handleToolTip}
                    tooltipOpen={tooltipOpen}
                    fullAddress={fullAddress}
                    installAddress={installLocationAddress}
                />
            </AppDrawer>

        </AppContainer>
    );
}

export default MyUnitsScreen;

