export const getCurrentGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour >= 5 && currentHour < 12) {
        return 'Good morning';
    } else if (currentHour >= 12 && currentHour < 18) {
        return 'Good afternoon';
    } else {
        return 'Good evening';
    }
};

export const getLocation = () => {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(resolve, reject);
        } else {
            reject(new Error('Geolocation is not supported by this browser.'));
        }
    });
};

export const getWeather = async (lat, lon, weatherApiKey) => {
    const response = await fetch(
        `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${weatherApiKey}`
    );
    return await response.json();
};

export const convertTemperature = (kelvin, isMetric) => {
    if (isMetric) {
        return (kelvin - 273.15).toFixed(1) + ' °C';
    } else {
        return ((kelvin - 273.15) * 9/5 + 32).toFixed(1) + " °F";
    }
};
