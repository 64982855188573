import React, {useContext, useState} from 'react';
import {Box, Checkbox, CircularProgress, FormControlLabel, Typography} from "@mui/material";
import {Field, Form, Formik} from "formik";
import AppButton from "../shared/AppButton";
import styled from "@emotion/styled";
import AppAccordion from "../shared/AppAccordion";
import {MultiStepContext} from "../../contexts/MultiStepContext";
import {useApi} from "../../contexts/ApiProvider";
import {useNavigate} from "react-router-dom";
import {useAlert} from "../../contexts/AlertsProvider";

const TextContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '2em',
    textAlign: 'center',
});

const AccordionWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25em',
});

const ButtonContainer = styled(Box)({
    display: 'flex',
    marginTop: '1.5em',
    marginBottom: '3em',
    flexDirection: 'column',
});

function RegisterThree(props) {
    const navigate = useNavigate();
    const api = useApi();
    const alert = useAlert();
    const { formState, setFormState } = useContext(MultiStepContext);
    const [loading, setLoading] = useState(false);
    const { userType } = formState;
    const handleContinue = async (values) => {
        setLoading(true);
        setFormState(prevState => ({...prevState, ...values}));

        let submitData = {...formState};
        delete submitData.confirmPassword;

        if (userType === 'Contractor') {
            submitData = {
                ...submitData,
                first_name: submitData.firstName,
                last_name: submitData.lastName,
                email: submitData.email,
                password: submitData.password,
                phone_number: submitData.phoneNumber,
                company_name: submitData.company,
                role: submitData.role
            };
        } else {
            submitData = {
                first_name: submitData.firstName,
                last_name: submitData.lastName,
                email: submitData.email,
                password: submitData.password,
                phone_number: submitData.phoneNumber,
            };
        }

        try {
            const response = await api.post('/users', submitData);
            console.log(submitData)
            console.log(response)
            if (response.ok) {
                navigate('/login');
                alert('Successfully registered! Please log-in', 'success');
            } else if (response.status === 404) {
                alert('Your information is already in use', 'error')
            } else {
                alert('Could not create account', 'error')
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    return (
        <>
            <TextContainer>
                <Typography variant="h6">Almost Done</Typography>
                <Typography style={{ textAlign: 'center', color: '#959292', marginTop: '.25em', fontSize: '0.9em' }}>Review the Terms of Service and Privacy Policy</Typography>
            </TextContainer>
            <AccordionWrapper>
                <AppAccordion title="Terms of Service and Privacy Policy">
                    <li style={{ fontFamily: 'Jura', fontSize: '0.9em', marginLeft: '1em' }}>
                        No Responsibility Clause: <br /><br />
                        By using our application, you agree to the following terms:
                        <ul >
                            <li>Our application is provided on an "as is" and "as available" basis without any warranties or representations, either express or implied.</li>
                            <li>We do not warrant that the application will be uninterrupted, error-free, or free from viruses or other harmful components.</li>
                            <li>We are not responsible for any damages or loss of data that may occur from the use of our application.</li>
                            <li>You acknowledge that the use of our application is at your own risk and discretion.</li>
                            <li>We reserve the right to modify or discontinue the application at any time without prior notice.</li>
                            <li>In no event shall we be liable for any direct, indirect, incidental, special, or consequential damages arising out of the use or inability to use the application.</li>
                        </ul>
                    </li>
                </AppAccordion>
            </AccordionWrapper>
            <Formik
                initialValues={formState}
                onSubmit={handleContinue}
            >
                {({ values }) => (
                    <Form>
                        <Field name="termsAccepted">
                            {({ field }) => (
                                <FormControlLabel
                                    style={{ marginTop: '2em' }}
                                    control={
                                        <Checkbox {...field} checked={!!field.value} sx={{ '& .MuiSvgIcon-root': { fontSize: "1.75em" } }} color="success" />
                                    }
                                    label={
                                        <Typography fontWeight="700" fontSize="0.75em" color="#959292" align="center">
                                            I acknowledge that I have read and understand the Anesi Terms of Service and Privacy Policy.
                                        </Typography>
                                    }
                                    labelPlacement="end"
                                />
                            )}
                        </Field>
                        <ButtonContainer>
                            <div style={{ position: 'relative' }}>
                                <AppButton
                                    variant="primary"
                                    type="submit"
                                    disabled={!values.termsAccepted || loading}
                                    style={{ minWidth: '100px' }}
                                >
                                    Finish
                                </AppButton>
                                {loading &&
                                    <CircularProgress
                                        size={24}
                                        style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12 }}
                                    />
                                }
                            </div>
                        </ButtonContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default RegisterThree;

