import * as Yup from 'yup';
import { useFormikContext, Field } from 'formik';
import { TextField, Box, Typography } from '@mui/material';

export const phoneRegExp = /^\d{10}$/;
export const zipRegExp = /^\d{5}$/;
export const stateRegExp = /^[A-Z]{2}$/;

export const validationSchema = Yup.object({
    fullName: Yup.string().required('Full Name is required'),
    company_name: Yup.string().required('Company Name is required'),
    address_line_1: Yup.string().required('Address is required'),
    city: Yup.string().required('City is required'),
    state_abbreviation: Yup.string()
        .matches(stateRegExp, 'State must be exactly 2 uppercase letters')
        .required('State is required'),
    zipcode: Yup.string()
        .matches(zipRegExp, 'ZIP Code must be exactly 5 digits')
        .required('ZIP Code is required'),
    phone_number: Yup.string()
        .matches(phoneRegExp, 'Phone Number must be exactly 10 digits, no characters')
        .required('Phone Number is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    confirmEmail: Yup.string()
        .oneOf([Yup.ref('email'), null], 'Emails must match')
        .required('Confirm Email is required'),
});

export const formatPhoneNumber = (value) => {
    return value.replace(/\D/g, '').slice(0, 10);
};

export const formatZipCode = (value) => {
    return value.replace(/\D/g, '').slice(0, 5);
};

export const formatState = (value) => {
    return value.replace(/[^A-Za-z]/g, '').toUpperCase().slice(0, 2);
};

export const FormTextField = ({ name, label, handleChange, ...props }) => (
    <Field name={name}>
        {({ field, meta }) => (
            <Box>
                <Typography variant="body2">{label}</Typography>
                <TextField
                    fullWidth
                    variant="standard"
                    {...field}
                    {...props}
                    onChange={(e) => {
                        if (handleChange) {
                            handleChange(e);
                        }
                        field.onChange(e);
                    }}
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                    InputProps={{
                        sx: {
                            '&:after': {
                                borderBottomColor: '#4EB6B5',
                            },
                        },
                    }}
                />
            </Box>
        )}
    </Field>
);

export const PhoneNumberInput = ({ label }) => {
    const { setFieldValue, values } = useFormikContext();

    const handlePhoneNumberChange = (ev) => {
        setFieldValue('phone_number', formatPhoneNumber(ev.target.value));
    };

    return (
        <FormTextField
            name="phone_number"
            label={label}
            value={values.phone_number}
            handleChange={handlePhoneNumberChange}
            inputProps={{ maxLength: 10 }}
        />
    );
};

export const StateInput = ({ label }) => {
    const { setFieldValue, values } = useFormikContext();

    const handleStateChange = (ev) => {
        setFieldValue('state_abbreviation', formatState(ev.target.value));
    };

    return (
        <FormTextField
            name="state_abbreviation"
            label={label}
            value={values.state_abbreviation}
            handleChange={handleStateChange}
            inputProps={{ maxLength: 2 }}
        />
    );
};

export const ZipCodeInput = ({ label }) => {
    const { setFieldValue, values } = useFormikContext();

    const handleZipCodeChange = (ev) => {
        setFieldValue('zipcode', formatZipCode(ev.target.value));
    };

    return (
        <FormTextField
            name="zipcode"
            label={label}
            value={values.zipcode}
            handleChange={handleZipCodeChange}
            inputProps={{ maxLength: 5 }}
        />
    );
};
