import {createContext, useContext, useState, useCallback} from "react";

export const AlertContext = createContext({});
let alertTimer;

export default function AlertsProvider({children}) {
    const [alertMessage, setAlertMessage] = useState({});
    const [visible, setVisible] = useState(false);

    const hideAlert = useCallback(() => {
        setVisible(false);
    }, []);

    const alert = useCallback((message, type, duration = 5) => {
        if (alertTimer) {
            clearTimeout(alertTimer);
            alertTimer = undefined;
        }
        setAlertMessage({message, type});
        setVisible(true);
        if (duration) {
            alertTimer = setTimeout(hideAlert, duration * 1000);
        }
    }, [hideAlert]);

    return (
        <AlertContext.Provider value={{alert, hideAlert, alertMessage, visible}}>
            {children}
        </AlertContext.Provider>
    );
}

export function useAlert() {
    return useContext(AlertContext).alert;
}

