import React from 'react';
import {TextField} from "@mui/material";
import {useField} from 'formik';

// Replace with new validationandinput file...
function AppTextInput({label, ...props}) {
    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error: '';

    return (
        <TextField variant="standard" label={label} {...field} helperText={errorText} error={!!errorText}  {...props}
           sx={{
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: '#959292',
                    borderRadius: 3,
                },
                '&:hover fieldset': {
                    borderColor: '#37589D',
                },
                '&.Mui-focused fieldset': {
                    borderColor: '#37589D',
                },
            },
            '& .MuiOutlinedInput-input': {
                backgroundColor: 'white',
            },
            }}
        />
    );
}


export default AppTextInput;
