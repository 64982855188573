import React from 'react';
import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { CheckCircle, Error } from '@mui/icons-material';

const Container = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #4EB6B5',
    borderRadius: '15px',
    padding: '0.5em',
    margin: '1em 0',
    justifyContent: 'space-evenly',
    backgroundColor: 'white',
});

const InfoText = styled(Typography)({
    marginRight: '0.75em',
});

const StatusContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
});

function UnitHeader({ unit, sn, status }) {
    const StatusIcon = status === 'OK' ? CheckCircle : Error;
    const iconColor = status === 'OK' ? '#4EB6B5' : '#B1494A';

    return (
        <Container>
            {unit && (
                <InfoText variant="body2">
                    Unit: {unit}
                </InfoText>
            )}
            <InfoText variant="body2">
                SN: {sn}
            </InfoText>
            {status && (
                <StatusContainer>
                    <InfoText variant="body2" >
                        System:
                    </InfoText>
                    <StatusIcon fontSize="small" style={{ color: iconColor }} />
                </StatusContainer>
            )}
        </Container>
    );
}

export default UnitHeader;
