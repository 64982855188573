import { useFormikContext } from 'formik';
import AppTextInput from './shared/AppTextInput';

const PhoneNumberInput = ({ label, placeholder }) => {
    const { setFieldValue, values } = useFormikContext();

    const handlePhoneNumberChange = (e) => {
        const { value } = e.target;
        if (value === '') {
            setFieldValue("phoneNumber", '');
            return;
        }

        let onlyNums = value.replace(/\D/g, '');
        if (onlyNums.length === 0) {
            onlyNums = '';
        } else if (onlyNums.length < 4) {
            onlyNums = `(${onlyNums}`;
        } else if (onlyNums.length < 7) {
            onlyNums = `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
        } else {
            onlyNums = `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
        }

        setFieldValue("phoneNumber", onlyNums);
    };

    return (
        <AppTextInput
            label={label}
            name="phoneNumber"
            value={values.phoneNumber}
            onChange={handlePhoneNumberChange}
            placeholder={placeholder}

        />
    );
};

export default PhoneNumberInput;
