import React from 'react';
import {useContext} from "react";
import {AlertContext} from "../../contexts/AlertsProvider";
import Alert from '@mui/material/Alert';
import {Snackbar, Slide, useMediaQuery, Grow} from "@mui/material/";
function AlertMessage(props) {
    const isDesktop = useMediaQuery('(min-width:1024px)');
    const {alertMessage, visible, hideAlert} = useContext(AlertContext);
    function SlideTransition(props) {
        const direction = isDesktop ? "left" : "up";
        return <Slide {...props} direction={direction} />;
    }


    return (
        <Snackbar
            TransitionComponent={SlideTransition}
            style={isDesktop ? { top: '1em', right: '1em' } : { marginBottom: '2em' }}
            anchorOrigin={isDesktop ? { vertical: 'top', horizontal: 'right' } : { vertical: 'bottom', horizontal: 'center' }}
            open={visible}
            onClose={hideAlert}
        >
            <Alert sx={{ borderRadius: '7px', textAlign: 'center', color: 'white', fontWeight: '700' }} elevation={10} icon={false} severity={alertMessage.type || 'info'} variant="filled">
                {alertMessage.message}
            </Alert>
        </Snackbar>
    );
}

export default AlertMessage;
