import React, {useEffect, useState} from 'react';
import AppContainer from "../components/shared/AppContainer";
import {Box, CircularProgress, Typography} from "@mui/material";
import styled from "@emotion/styled";
import {Form, Formik} from "formik";
import AppTextInput from "../components/shared/AppTextInput";
import AppButton from "../components/shared/AppButton";
import * as Yup from "yup";
import {useAlert} from "../contexts/AlertsProvider";
import {useApi} from "../contexts/ApiProvider";
import {useNavigate, useLocation} from "react-router-dom";
import AppHeader from "../components/shared/AppHeader";

const MainContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '2em',
    marginTop: '1em',
    gap: '0.25em',
});

const validationSchema = Yup.object().shape({
    password: Yup.string()
        .matches(
            /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            "Must contain 8 characters, one uppercase, one number and one special character"
        )
        .required('Required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Required'),
});

function PasswordResettingScreen(props) {
    const alert = useAlert();
    const [loading, setLoading] = useState(false);
    const api = useApi();
    const navigate = useNavigate();
    const { search } = useLocation();
    const token = new URLSearchParams(search).get('token');

    useEffect(() => {
        if (!token) {
            navigate('/');
        }
    }, [token, navigate]);

    const handleSubmit = async (values) => {
        setLoading(true);
        const submitData = {
            reset_token: token,
            new_password: values.confirmPassword,
        };
        const response = await api.put('/tokens/reset', submitData);
        if (response.ok) {
            alert('Your password has been reset', 'success');
            navigate('/login');
        } else {
            alert('Password could not be reset. Please try again', 'error');
            navigate('/password_reset');
        }
        setLoading(false);
    }

    return (
        <AppContainer>
            <AppHeader backButtonRoute="/" />
            <MainContainer>
                <Typography variant="h6">Lets reset your password!</Typography>
                <Typography style={{ textAlign: 'center', color: '#959292' }}>Please fill out the fields below and you will be all set.</Typography>
            </MainContainer>
            <Formik initialValues={{ password: '', confirmPassword: ''}} onSubmit={handleSubmit} validationSchema={validationSchema}>
                <Form>
                    <AppTextInput  name="password" label="Password" type="password" placeholder="Password"/>
                    <AppTextInput  name="confirmPassword" label="Password Again"  type="password" placeholder="Confirm Password"/>
                    <div style={{ position: 'relative' }}>
                        <AppButton disabled={loading} variant="primary" type="submit" style={{ marginTop: '1em' }}>Reset Password</AppButton>
                        {loading && <CircularProgress size={24} style={{ position: 'absolute', top: '60%', left: '50%', marginTop: -12, marginLeft: -12 }} />}
                    </div>
                </Form>
            </Formik>
        </AppContainer>
    );
}

export default PasswordResettingScreen;
