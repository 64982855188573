import React from 'react';
import AppCard from "./shared/AppCard";
import {Box, Typography} from "@mui/material";
import AppDivider from "./shared/AppDivider";
import styled from "@emotion/styled";

const StyledCard = styled(AppCard)({
    borderColor: 'transparent',
    height: '100%',
    backgroundColor: '#FCFCFF',
});

function AppFaultsCard({type, frequency, occuranceDate, fault}) {
    return (
        <StyledCard>
            <Box paddingX="0.35em">
                <Typography fontWeight="700" fontSize="1.125em" style={{textAlign: 'center', marginBottom: '1.25em'}}>
                    {fault}
                </Typography>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography color="#959292" >Type:</Typography>
                    <Typography fontSize="1.125em" fontWeight="700" ml={2}>{type}</Typography>
                </Box>
                <AppDivider customPadding=".75em" inCard="true"/>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography color="#959292" >Frequency (Monthly):</Typography>
                    <Typography fontSize="1.125em" fontWeight="700" ml={2}>{frequency}</Typography>
                </Box>
                <AppDivider customPadding=".75em" inCard="true"/>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography color="#959292" >Occurred on:</Typography>
                    <Typography fontSize="1.125em" fontWeight="700" ml={2}>{occuranceDate}</Typography>
                </Box>
                {/*<Box display="flex" justifyContent="flex-end" mt={'1.5em'}>*/}
                {/*    <Typography color="#37589D" fontWeight="700" fontSize="1em">Get assistance?</Typography>*/}
                {/*</Box>*/}
            </Box>
        </StyledCard>
    );
}

export default AppFaultsCard;
